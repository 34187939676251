








import { Component, Vue } from "vue-property-decorator";
import RevisarReserva from "@/components/Reserva/RevisarReserva.vue";

@Component({
  components: {
    RevisarReserva
  }
})
export default class RevisarReservaView extends Vue {}
