








import { Component, Vue } from "vue-property-decorator";
import Reserva from "@/components/Reserva/Reserva.vue";

@Component({
  components: {
    Reserva
  }
})
export default class ReservaView extends Vue {}
